import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faShoppingCart,
  faCartPlus,
  faPlusCircle,
  faMinusCircle,
  faWindowClose,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faQuestionCircle,
  faHome,
  faArrowCircleDown,
  faArrowCircleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"
export default function InitializeIcon() {
  library.add(
    faShoppingCart,
    faCartPlus,
    faPlusCircle,
    faMinusCircle,
    faWindowClose,
    faCheckCircle,
    faTimesCircle,
    faSpinner,
    faQuestionCircle,
    faHome,
    faArrowCircleDown,
    faArrowCircleUp,
    faSearch
  )
}
