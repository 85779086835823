import React from 'react'
import styles from "static/css/maintenance.module.css"
import Logo from "static/images/logo.png"
export default function Maintenance() {
    return (
        <div className={styles.Maintenance}>
            <img className={styles.Logo} src={Logo} alt="Elabox logo"/>
            <h1>Maintenance</h1>            
        </div>
    )
}
