import { selector } from 'recoil'
import {
  CartState,
  CurrencyState,
  ModalState,
  NewsLetterMessageState,
  ProductsState,
  TimerState,
} from 'state/atoms'
import { mapByKeys } from 'utils'
export const ProductsSelector = selector({
  key: 'ProductsSelector', // unique ID (with respect to other atoms/selectors)
  get: async ({ get }) => {
    const products = get(ProductsState)
    return products
  },
})
export const CartSelector = selector({
  key: 'CartSelector',
  get: ({ get }) => {
    const cart = get(CartState)
    return cart
  },
})
export const CartQuantitySelector = selector({
  key: 'CartQuantitySelector',
  get: ({ get }) => {
    const cart = get(CartState)
    let quantity = 0
    mapByKeys(cart.groupOfProducts).map((key) => {
      const products = cart.groupOfProducts[key]
      products.forEach((product) => {
        quantity += product.quantity
      })
    })
    return quantity
  },
})

export const TimerSelector = selector({
  key: 'TimerSelector',
  get: ({ get }) => {
    return get(TimerState)
  },
})
export const CurrencySelector = selector({
  key: 'CurrencySelector',
  get: ({ get }) => {
    return get(CurrencyState)
  },
})
export const NewsLetterMessageSelector=selector({
  key:"NewsLetterMessageSelector",
  get:({get})=>{
    return get(NewsLetterMessageState)
  }
})

export const ModalSelector = selector({
  key:"ModalSelector",
  get:({get})=>{
    return get(ModalState)
  }
})

export const SettingsSelector = selector({
  key:"SettingsSelector",
  get:({get})=>{
    return get(SettingsSelector)
  }
})