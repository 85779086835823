import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import io from 'socket.io-client'
import { default_cart } from 'state/atoms'
const BROADCAST_SERVER_URL = window.location.hostname
export const broadcast_server = io(BROADCAST_SERVER_URL, {
  transports: ['websocket'],
  forceNew: true,
  secure: true,
  reconnect: true,
  rejectUnauthorized: false,
  path: '/broadcast',
})
function Socket({ children }) {
  const [isSocketErrorDisplayed, setIsSocketErrorDisplayed] = useState(false)
  const displayError = () => {
    if (!isSocketErrorDisplayed) {
      toast.error('Connection error. Please refresh the page.')
      setIsSocketErrorDisplayed(true)
    }
  }
  useEffect(() => {
    broadcast_server.on('connect', () => {
      broadcast_server.emit('subscribe', default_cart.order_id)
    })
    broadcast_server.on('connect_error', () => {
      displayError()
      setTimeout(() => {
        broadcast_server.connect()
      }, 1000)
    })
    broadcast_server.on('disconnect', () => {
      console.log('broadcast server disconnected')
      displayError()
      broadcast_server.emit('unsubscribe', default_cart.order_id)
    })
  }, [])
  return children
}
export default Socket
