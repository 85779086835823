import axios from "axios"
export const PUBLIC_URI = "https://" + window.location.hostname + "/backend/"
let ajaxRequest = null
export const getProducts = async () => {
  const { data } = await axios.get(`${PUBLIC_URI}products`)
  const products = Object.keys(data).map((key) => data[key])
  return products
}
export const addOrdersIntoPending = async (order) => {
  const { data } = await axios.post(`${PUBLIC_URI}store`, order)
  return data
}
export const getCurrency = async () => {
  const { data } = await axios.get(`${PUBLIC_URI}currency`)
  return data
}
export const getPendingOrder = async (transaction_id) => {
  const { data } = await axios.get(`${PUBLIC_URI}tracking/${transaction_id}`)
  return data
}
export const subscribeToNewsLetter = async (email) => {
  const { data } = await axios.post(`${PUBLIC_URI}newsletter/`, { email })
  return data
}
export const getRate = async (information) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  const { data } = await axios.post(`${PUBLIC_URI}rates/`, information, {
    cancelToken: ajaxRequest.token,
  })
  return data
}
export const getOrder = async (id) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  const { data } = await axios.post(
    `${PUBLIC_URI}order/search/`,
    { id },
    {
      cancelToken: ajaxRequest.token,
    }
  )
  return data
}
export const getBitfinexOrderId = async (order) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  const { data } = await axios.post(
    `${PUBLIC_URI}bitfinex/create_invoice/`,
    { order },
    {
      cancelToken: ajaxRequest.token,
    }
  )
  return data
}

export const getSettings = async ()=>{
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  const { data } = await axios.get(
    `${PUBLIC_URI}settings/`,
    {
      cancelToken: ajaxRequest.token,
    }
  )
  return data 
}