import React from "react"
import Logo from "static/images/logo.png"
import styles from "static/css/landing.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
export default function Landing() {
  return (
    <div className={styles.Landing}>
      <FontAwesomeIcon icon="spinner" size="5x" spin />
    </div>
  )
}
