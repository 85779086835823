import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getOrder } from "backend/api"
import { ucfirst, mapByKeys } from "utils"
import styles from "static/css/order.module.css"
import Landing from "components/landing"
import { isMobile } from "react-device-detect"
export default function Order() {
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState({})
  const { id } = useParams()
  const getStatusMessage = (status) => {
    let message = ""
    switch (status) {
      case "completed":
        message = <p>Status: Completed</p>
        break
      case "processing":
        message = <p>Status: Processing</p>
        break
      case "waiting_for_txtid":
        message = <p>Status: Waiting for transaction id</p>
        break
      case "failed":
        message = (
          <>
            <p>
              We found your Order, but payment transaction details are missing.
            </p>
            <p>
              If this was already paid, Please email{" "}
              <a style={{ color: "white" }} href="mailto:contact@elabox.com">
                contact@elabox.com
              </a>{" "}
              to notify us
            </p>
          </>
        )
        break
      case "cancelled":
        message = (
          <>
            <p>
              We found your Order, but payment transaction details are missing.
            </p>
            <p>
              If this was already paid, Please email{" "}
              <a style={{ color: "white" }} href="mailto:contact@elabox.com">
                contact@elabox.com
              </a>{" "}
              to notify us
            </p>
          </>
        )
        break
      default:
        break
    }
    return message
  }
  useEffect(() => {
    const handleSearchOrder = async () => {
      const order = await getOrder(id)
      setOrder(order)
      setIsLoading(false)
    }
    handleSearchOrder()
  }, [id])
  const statusMessage = getStatusMessage(order.status)
  if (isLoading) {
    return <Landing />
  }
  return (
    <div className={isMobile ? styles.OrderMobile : styles.Order}>
      <div className={styles.status}>
        {order.hasOwnProperty("status") &&
        (order.status === "pending" ||
          order.status === "processing" ||
          order.status === "waiting_for_txtid" ||
          order.status === "completed") ? (
          <>
            <p>Order ID: {order.order_id}</p>
            <p>Transaction ID: {order.transaction_id}</p>
            <p>
              Name: {ucfirst(order.info.first_name)}{" "}
              {ucfirst(order.info.last_name)}
            </p>
            {statusMessage}
            <p>
              Address: {order.info.address1},{order.info.address2}
              <br />
              {order.info.city},{order.info.region},{order.info.country},
              {order.info.postal_code}
            </p>
            {/* <p>Status: {ucfirst(order.status)}</p> */}
            <p>Items:</p>
            {order.groupOfProducts &&
              mapByKeys(order.groupOfProducts)?.map((key) => {
                const products = order.groupOfProducts[key]
                return products.map((product) => {
                  return (
                    <p className={styles.item} key={product._id}>
                      {product.name} ({product.selectedColor}) X{" "}
                      {product.quantity}
                    </p>
                  )
                })
              })}
          </>
        ) : (
          <>
            {order.status === "cancelled" || order.status === "failed" ? (
              statusMessage
            ) : (
              <p>Not Found</p>
            )}
          </>
        )}
      </div>
    </div>
  )
}
