import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import { isMobile } from "react-device-detect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "static/css/modal.module.css"
import btnStyles from "static/css/button.module.css"
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "50%",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(46, 49, 49, 0.60)",
  },
}
export function ModalComponent({
  title,
  show,
  top,
  children,
  handleOnClose,
  showClose = true,
}) {
  const [modalIsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(show)
  }, [show])
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    handleOnClose()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      style={{ ...customStyles, content: { ...customStyles.content, top } }}
      contentLabel=""
      ariaHideApp={false}
    >
      <div className={isMobile ? styles.modalHeaderMobile : styles.modalHeader}>
        <h2>{title}</h2>
        {showClose && (
          <button
            className={`${btnStyles.info} ${styles.btnClose}`}
            onClick={closeModal}
          >
            <FontAwesomeIcon icon="window-close" />
          </button>
        )}
      </div>
      <div className={styles.modalBody}>
        {children({ openModal, closeModal })}
      </div>
    </Modal>
  )
}
export default function ModalContainer({
  title,
  show,
  top = "50%",
  children,
  handleOnClose,
  showClose,
}) {
  return ReactDOM.createPortal(
    <ModalComponent
      title={title}
      show={show}
      top={top}
      children={children}
      handleOnClose={handleOnClose}
      showClose={showClose}
    />,
    document.getElementById("modal")
  )
}
