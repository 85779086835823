import React, { Suspense, useEffect, lazy } from "react"
import { Route } from "react-router-dom"
import { useRecoilState } from "recoil"
import { ToastContainer, Flip } from "react-toastify"
import produce from "immer"
import { getCurrency } from "backend/api"
import { getProducts } from "backend/api"
import { CurrencyState, ProductsState } from "state/atoms"
import Landing from "components/landing"
import NewsLetter from "components/newsletter"
import { display2Decimal } from "utils"
const Products = lazy(() => import("components/dashboard/products"))
const Checkout = lazy(() => import("components/dashboard/checkout"))
const Cart = lazy(() => import("components/dashboard/cart"))
export default function DashBoard({ match, restartTimer, stopTimer }) {
  const [products, setProducts] = useRecoilState(ProductsState)
  const [currency, setCurrency] = useRecoilState(CurrencyState)
  useEffect(() => {
    const handleGetCurrency = async () => {
      const currency = await getCurrency()
      setCurrency(currency)
    }
    handleGetCurrency()
  }, [])
  useEffect(() => {
    const handleSetProducts = async () => {
      const products = await getProducts()
      const nextProductsState = produce(products, (draft) => {
        draft = draft.map((product) => {
          if (product.name === "Elabox") {
            product["selectedColor"] = "Black & White"
          }
          return product
        })
      })
      setProducts(nextProductsState)
    }
    handleSetProducts()
  }, [])
  return (
    <div>
      <ToastContainer
        hideProgressBar
        position="bottom-right"
        closeOnClick
        autoClose={5000}
        limit={1}
        transition={Flip}
        closeButton={null}
      />
      <NewsLetter />
      <Suspense fallback={<Landing />}>
        <Route exact path={`${match.path}`} component={Products} />
        <Route path={`${match.path}cart`} component={Cart} />
        <Route
          path={`${match.path}checkout`}
          render={(props) => (
            <Checkout
              {...props}
              restartTimer={restartTimer}
              stopTimer={stopTimer}
            />
          )}
        />
      </Suspense>
    </div>
  )
}
