import React from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil"
import initIcons from "icons"
import Socket from "Socket"
import "static/css/index.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
// import reportWebVitals from './reportWebVitals'
initIcons()
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Socket>
        <App />
      </Socket>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
