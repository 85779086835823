import { atom } from "recoil"
import { customAlphabet } from "nanoid"
const nanoid = customAlphabet(
  "0123456789ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz",
  10
)
export const default_cart = {
  order_id: nanoid(),
  transaction_id: "",
  currencyConversion: {},
  info: {},
  groupOfProducts: {},
  subTotal: 0.0,
  grandTotal: 0.0,
  shippingFee: 0.0,
  canPurchase: false,
  isShippingFeePaid: false,
  isEmailSent: false,
  currencyUsed: "USD",
}
export const default_timer = {
  days: undefined,
  hours: undefined,
  minutes: undefined,
  months: undefined,
  seconds: undefined,
  years: undefined,
}
export const ProductsState = atom({
  key: "ProductsState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})
export const CartState = atom({
  key: "CartState",
  default: default_cart,
})

export const TimerState = atom({
  key: "TimerState",
  default: default_timer,
})
export const CurrencyState = atom({
  key: "CurrencyState",
  default: {
    elastos: 0,
  },
})
export const ModalState = atom({
  key: "ModalState",
  default: "",
})

export const AddressState = atom({
  key: "AddressState",
  default: "processing",
})

export const CheckoutState = atom({
  key: "CheckoutState",
  default: false,
})
export const NewsLetterMessageState = atom({
  key: "NewsLetterMessageState",
  default: "",
})

export const SettingsState = atom({
  key:"SettingsState",
  default:{
    maintenance: false
  }  
})