import { useEffect, useState } from "react"
import { Redirect, useParams } from "react-router-dom"
import { isMobile } from "react-device-detect"
import { getPendingOrder } from "backend/api"
import { broadcast_server } from "Socket"
import { ucfirst, mapByKeys } from "utils"
import styles from "static/css/tracking.module.css"
import Landing from "components/landing"
export default function Tracking() {
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState({})
  const [order, setOrder] = useState({})
  const { transaction_id } = useParams()
  useEffect(() => {
    const getOrder = async () => {
      const order = await getPendingOrder(transaction_id)
      setOrder(order)
    }
    if (broadcast_server.connected) {
      getOrder()
    }
    broadcast_server.on("connect", async () => {
      getOrder()
    })
    broadcast_server.on("transaction_status", async (data) => {
      if (data.txid === transaction_id) {
        if (data.status === "processing" || data.status === "completed") {
          const order = await getPendingOrder(transaction_id)
          setOrder(order)
          setStatus(data)
          setIsLoading(false)
        }
      }
    })
  }, [])
  if (status.confirmations > 1 || order.status === "completed") {
    return <Redirect to='/order/success' />
  }
  if (isLoading) {
    return <Landing />
  }
  return (
    <div className={isMobile ? styles.TrackingMobile : styles.Tracking}>
      <p className={styles.status}>
        {order.hasOwnProperty("status") &&
        (order.status === "failed" ||
          order.status === "processing" ||
          order.status === "completed") ? (
          <>
            <span>Transaction ID: {transaction_id}</span>
            <span>Status: {ucfirst(order.status)}</span>
            <span>
              Confirmations:{" "}
              {!status.hasOwnProperty("confirmations")
                ? 0
                : status.confirmations}
            </span>
            <span>Items:</span>
            {order.groupOfProducts &&
              mapByKeys(order.groupOfProducts)?.map((key) => {
                const products = order.groupOfProducts[key]
                return products.map((product) => {
                  return (
                    <span className={styles.item} key={product._id}>
                      {product.name} ({product.selectedColor}) X{" "}
                      {product.quantity}
                    </span>
                  )
                })
              })}
          </>
        ) : (
          <>
            <span>Not Found</span>
          </>
        )}
      </p>
    </div>
  )
}
