import React from 'react'
import { Link } from 'react-router-dom'
import styles from 'static/css/notification.module.css'
import buttonStyles from 'static/css/button.module.css'
export default function Success() {
  return (
    <div className={styles.Success}>
      <h2>This order is completed</h2>
      <p>We sent an email to you.</p>
      <Link to="/">
        <button className={buttonStyles.info}>Back to store</button>
      </Link>
    </div>
  )
}
