import {useEffect,useState} from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { isMobile } from "react-device-detect"
import {useRecoilState} from "recoil"
import Header from "components/header"
import Dashboard from "components/dashboard"
import Tracking from "components/tracking"
import OrderSearch from "components/search/order"
import Success from "components/notification/success"
import styles from "static/css/app.module.css"
import Timer from "components/partials/Timer"
import { SettingsState } from "state/atoms"
import { getSettings } from "backend/api"
import Maintenance from "components/partials/Maintenance"
import Landing from "components/landing"
function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useRecoilState(SettingsState)
  useEffect(() => {
    const handleGetSettings= async ()=>{
       const settings = await getSettings()
       setSettings(settings)
       setIsLoading(false)
    }
    handleGetSettings()
  }, [])
  if(isLoading){
    return <Landing/>
  }
  if(settings.maintenance){
    return <Maintenance/>
  }
  return (
    <div className={isMobile ? styles.AppMobile : styles.App}>
      <Router>
        {
          <Timer>
            {({ restartTimer, stopTimer }) => (
              <>
                <Header stopTimer={stopTimer} />
                <Switch>
                  <Route
                    exact
                    path="/tracking/:transaction_id"
                    component={Tracking}
                  />
                  <Route
                    exact
                    path="/order/search/:id"
                    component={OrderSearch}
                  />
                  <Route exact path="/order/success" component={Success} />
                  <Route
                    path="/"
                    render={(props) => (
                      <Dashboard
                        {...props}
                        restartTimer={restartTimer}
                        stopTimer={stopTimer}
                      />
                    )}
                  />
                </Switch>
              </>
            )}
          </Timer>
        }
      </Router>
    </div>
  )
}

export default App
