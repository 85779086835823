import { useState } from "react"
import { useForm } from "react-hook-form"
import Modal from "components/partials/Modal"
import { useRecoilState, useRecoilValue } from "recoil"
import { ModalState } from "state/atoms"
import styles from "static/css/newsletter.module.css"
import buttonStyles from "static/css/button.module.css"
import { subscribeToNewsLetter } from "backend/api"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NewsLetterMessageSelector } from "state/selectors"
import { isMobile } from "react-device-detect"
export default function NewsLetter() {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({})
  const [isProcessing, setIsProccessing] = useState(false)
  const [modal, setModal] = useRecoilState(ModalState)
  const newsLetterMessage = useRecoilValue(NewsLetterMessageSelector)
  const showNewsLetterMessage = newsLetterMessage?.length > 0
  const handleOnClose = () => {
    setModal("")
    setValue("email", "")
    clearErrors()
  }
  const handleOnSubmit = async (data) => {
    try {
      setIsProccessing(true)
      await subscribeToNewsLetter(data.email)
      toast.success("Email confirmation sent. Check your Inbox")
    } catch ({ response }) {
      if (response.data.includes("Email already subscribed")) {
        toast.info(response.data)
      } else {
        toast.error(response.data)
      }
    } finally {
      setIsProccessing(false)
    }
  }
  return (
    <div>
      <Modal
        title=""
        top="55%"
        show={modal === "newsletter-modal"}
        handleOnClose={handleOnClose}
      >
        {(modalFunctions) => (
          <div
            className={isMobile ? styles.NewsletterMobile : styles.Newsletter}
          >
            <h1>You want this option?</h1>
            {showNewsLetterMessage && <p>{newsLetterMessage}</p>}
            <form method="POST" onSubmit={handleSubmit(handleOnSubmit)}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  validate: {
                    isEmail: (v) => {
                      const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      return re.test(String(v).toLowerCase())
                    },
                  },
                })}
              />
              {errors.email?.type === "required" && (
                <span className={styles.error}>This field is required</span>
              )}
              {errors.email?.type === "isEmail" && (
                <span className={styles.error}>Valid email is required</span>
              )}
              <button
                className={`${buttonStyles.info} ${
                  isProcessing ? buttonStyles.disabled : undefined
                }`}
                type="submit"
              >
                {isProcessing ? (
                  <FontAwesomeIcon icon="spinner" size="2x" spin />
                ) : (
                  "Subscribe"
                )}
              </button>
            </form>
          </div>
        )}
      </Modal>
    </div>
  )
}
