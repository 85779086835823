export const display2Decimal = (number) => Math.round(number * 1e2) / 1e2
export const display7Decimal = (number) => Math.round(number * 1e7) / 1e7
export const displayNoDecimal = (number) => Math.round(number * 1e1) / 1e1
export const pad = (n) => (n < 10 ? "0" + n : n)
export const ucfirst = (str) => {
  if (typeof str !== "string") {
    return str
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const mapByKeys = (obj) => {
  return Object.keys(obj)
}
