import { useEffect, useState } from 'react'
import { intervalToDuration } from 'date-fns'
import { broadcast_server } from 'Socket'
import { useRecoilState } from 'recoil'
import {
  CartState,
  TimerState,
  default_timer,
  CheckoutState,
} from 'state/atoms'
export default function Timer({ children }) {
  const [seconds, setSeconds] = useState(300)
  const [timer, setTimer] = useRecoilState(TimerState)
  const [cart, setCart] = useRecoilState(CartState)
  const [checkoutStatus, setCheckoutStatus] = useRecoilState(CheckoutState)
  const startTimer = async (localSeconds) => {
    window.timerId = setInterval(() => {
      const duration = intervalToDuration({
        start: 0,
        end: localSeconds * 1000,
      })
      setTimer(duration)
      localSeconds -= 1
      setSeconds(localSeconds)
    }, 1000)
  }
  const restartTimer = () => {
    let addTime = seconds + 300
    if (addTime > 600) {
      addTime = 600
    }
    clearInterval(window.timerId)
    startTimer(addTime)
  }
  const stopTimer = () => {
    clearInterval(window.timerId)
    setTimer(default_timer)
  }
  useEffect(() => {
    if (broadcast_server.connected) {
      setCart({ ...cart, canPurchase: true })
    }
    broadcast_server.on('connect', () => {
      setCart({ ...cart, canPurchase: true })
    })
  }, [])
  useEffect(() => {
    if (window.location.href.includes('checkout')) {
      startTimer(seconds)
    } else {
      stopTimer()
    }
    window.scrollTo(0, 0)
  }, [window.location.href])
  useEffect(() => {
    const { days, hours, minutes, months, seconds, years } = timer
    if (
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      months === 0 &&
      seconds === 0 &&
      years === 0
    ) {
      alert('Time is up! You failed to complete this order.')
      if (cart) {
        setCheckoutStatus(false)
      }
      window.location.reload()
    }
  }, [timer])
  return children({ restartTimer, stopTimer })
}
