import React, { useState, useRef, useEffect } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isMobile } from "react-device-detect"
import { useRecoilState, useRecoilValue } from "recoil"
import { CartQuantitySelector, TimerSelector } from "state/selectors"
import { pad } from "utils"
import Logo from "static/images/logo.png"
import buttonStyles from "static/css/button.module.css"
import styles from "static/css/header.module.css"
import { CartState, CheckoutState } from "state/atoms"
const Header = ({ stopTimer }) => {
  const [showLinks, setShowLinks] = useState(isMobile ? false : true)
  const inputIdRef = useRef(null)
  const [cart, setCart] = useRecoilState(CartState)
  const [checkoutStatus, setCheckoutStatus] = useRecoilState(CheckoutState)
  const cartquantity = useRecoilValue(CartQuantitySelector)
  const timer = useRecoilValue(TimerSelector)
  const location = useLocation()
  const history = useHistory()
  const showBadge = cartquantity > 0 && !location.pathname.includes("checkout")
  const showCart = !location.pathname.includes("checkout")
  const showOrderSearch = !location.pathname.includes("checkout")
  const showTimer =
    timer.hours !== undefined &&
    timer.seconds !== undefined &&
    cartquantity > 0 &&
    location.pathname.includes("checkout")
  let showHeader =
    !location.pathname.includes("/tracking") &&
    !location.pathname.includes("/order/success")
  const handleMainSiteClick = () => {
    setCheckoutStatus(false)
    window.location.href = "https://www.elabox.com/"
  }
  const handleCartClick = () => {
    history.push("/cart")
  }
  const handleSearchOrder = (e) => {
    e.preventDefault()
    history.push(`/order/search/${inputIdRef.current.value}`)
  }
  useEffect(() => {
    if (!location.pathname.includes("order/search")) {
      if (inputIdRef.current) {
        inputIdRef.current.value = ""
      }
    }
  }, [location.pathname])
  if (!showHeader) {
    return null
  }
  return (
    <div className={isMobile ? styles.HeaderMobile : styles.Header}>
      <div>
        <div className={styles.logoContainer}>
          <p>Beta</p>
          <Link to="/">
            <img className={styles.logo} src={Logo} alt="Elabox Logo" />
          </Link>
        </div>
        <div
          className={styles.controlContainer}
          style={{ display: showLinks ? "flex" : "none" }}
        >
          <button
            className={buttonStyles.transparent}
            onClick={handleMainSiteClick}
          >
            <FontAwesomeIcon icon="home" />
            Main Site
          </button>
          <a target="_blank" href="https://www.elabox.com/faq" rel="noreferrer">
            <button className={buttonStyles.transparent}>
              <FontAwesomeIcon icon="question-circle" />
              FAQ
            </button>
          </a>
          {showOrderSearch && (
            <form
              className={styles.form}
              method="POST"
              onSubmit={handleSearchOrder}
            >
              <input
                type="text"
                name="search"
                placeholder="Order/Transaction ID"
                ref={inputIdRef}
              />
              <button className={buttonStyles.transparent} type="submit">
                <FontAwesomeIcon icon="search" size="2x" />
              </button>
            </form>
          )}
        </div>
      </div>
      {showTimer && (
        <div className={styles.timer}>
          {pad(timer.minutes)}:{pad(timer.seconds)}
        </div>
      )}
      {showCart && (
        <button className={buttonStyles.transparent} onClick={handleCartClick}>
          <FontAwesomeIcon
            className={styles.cartLogo}
            icon="shopping-cart"
            size="2x"
          />
        </button>
      )}

      {showBadge && (
        <span className={styles.badge}>
          {cartquantity > 9 ? "9+" : cartquantity}
        </span>
      )}
      {isMobile && (
        <FontAwesomeIcon
          className={styles.burger}
          icon={showLinks ? "arrow-circle-up" : "arrow-circle-down"}
          size="2x"
          onClick={() => {
            setShowLinks(!showLinks)
          }}
        />
      )}
    </div>
  )
}
export default Header
